import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import { plugin, defaultConfig } from '@formkit/vue'



const myBarredOwlApp = createApp(App)


myBarredOwlApp.use(plugin, defaultConfig).use(router)



myBarredOwlApp.mount('#app')



