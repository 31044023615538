import { createWebHistory, createRouter } from 'vue-router'
import Home from '../components/home'
import Show from "../components/show"
import Contact from "../components/contact"


const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/contact",
        component: Contact,
    },
    {
        path: "/show",
        component: Show
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;