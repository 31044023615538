<template>
	<div>
		<h2>Contact</h2>
		<p>Please get in touch with us!</p>
		
		<FormKit
			type="form"
			ref="form"
			v-model="formData"
			:form-class="submitted ? 'hide' : 'show'"
			id="bosb-register"
			:config="{ validationVisibility: 'submit' }"
			label="Register"
			#default="{ value }"
			submit-label= "Get in Touch"
			:actions="false"
			@submit="createCustomer">
			<div v-if="!submitted">
				<FormKit
					v-model="customer.fname"
					ref="input"
					type="text"
					name="fname"
					id="bosb-fname"
					placeholder="First Name"
					validation="required | length: 1"
					autocomplete="email"
				/>

				<FormKit
					v-model="customer.lname"
					ref="input"
					type="text"
					name="lname"
					id="bosb-lname"
					placeholder="Last Name"
					validation="required | length: 1"
				/>

				<FormKit
					v-model="customer.phone"
					ref="input"
					type="tel"
					name="phone"
					id="bosb-phone"
					placeholder="xxx-xxx-xxxx"
					validation="required | matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"
					:validation-messages="{
						matches: 'Phone number must be in the format xxx-xxx-xxxx'
					}"
					validation-visibility="dirty"
				/>

				<FormKit
					v-model="customer.email"
					ref="input"
					type="email"
					name="bosb-email"
					id="email"
					validation="required | email"
					placeholder="Email"
				/>

				<FormKit
					v-model="customer.message"
					type="textarea"
					name="bosb-msg"
					placeholder="Type your message.."
					rows="10"
					id="message"
					validation="required | message"
				/>
				
				<FormKit
					type="submit"
					label="Register"
				/>

				<pre wrap>{{ value }}</pre>
			</div>
			<div v-if="submitted">
				<h3>Submission successful!</h3>
			</div>
		</FormKit>
	</div>
</template>

<script>

	import { HTTP } from '../variables';
	import { ref } from 'vue'
	//import { FormKitMessages } from '@formkit/vue'
	
	export default {
		name: "Contact",
		data() {
			return{
				"customer" : {
					"fname" : "",
					"lname" : "",
					"phone" : "",
					"email" : "",
					"message" : ""
				}
			}
		},
		methods: {
			setup:()=>{
				const submitted = ref(false);

				return {
					submitted
				}
			},
			createCustomer: function() {

				console.log(JSON.stringify(this.customer))
				
				HTTP.post("contact", this.customer)
				.then((res) => {
					//this.$refs.form.reset();
					//console.log(res.data);
					
					if(res.data.msg === "Validation Failed" || res.data.msg === undefined){
						console.log(res.data.msg)
					}
					else{
						this.submitted = ref(true)
						this.$formkit.reset('bosb-register')
					}
				})
				.catch((err)=>{
					alert(err);
				})
			}
		}
	};
</script>

<style scoped>
	/*styles*/
</style>